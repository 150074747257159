<template>
  <v-container id="genetic-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" sm="6" align-self="center">
            <page-top-title>
              <template v-slot:toptitle-icon>mdi-dna</template>
              <template v-slot:toptitle-text>{{ $t("Usuarios") }} </template>
            </page-top-title>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-card>
        <v-card-title>
          Usuarios
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('buscar')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table :headers="headers" :items="users" :search="search">
          <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >¿Seguro que quieres eliminarlo?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
            <v-icon
              small
              @click="
                $router.push({
                  name: `AdminGeneticProfile`,
                  params: { userId: item.id }
                })
              "
            >
              mdi-dna
            </v-icon>
            <v-icon
              small
              @click="
                $router.push({
                  name: `GenesWeights`,
                  params: { userId: item.id }
                })
              "
            >
              mdi-album
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import axios from "@/services/axios.js";
import { mapGetters } from "vuex";
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";

export default {
  components: { PageTopTitle },
  data() {
    return {
      search: "",
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "full_name"
        },
        {
          text: "Email",
          sortable: true,
          value: "email"
        },
        {
          text: "MSC",
          sortable: true,
          value: "MSC"
        },
        {
          text: "Tipo",
          sortable: true,
          value: "role"
        },
        { text: "Actions", value: "actions", sortable: false }
      ],
      users: []
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"])
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  mounted() {
    this.getUsersList();
  },
  methods: {
    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.users.indexOf(item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let self = this;
      return axios
        .post(
          `/.netlify/functions/delete-user?id=${
            self.users[self.editedIndex].id
          }`,
          {}
        )
        .then(function() {
          self.users.splice(self.editedIndex, 1);
          self.closeDelete();
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    getUsersList() {
      let self = this;
      axios({
        method: "get",
        url: "/.netlify/functions/list-users"
      }).then(res => {
        self.users = res.data;
      });
    }
  }
};
</script>
